<script>
import { mapGetters } from 'vuex';
import dateHelpers from '@/mixins/dateHelpers';
import UserSettingsService from '@/service/userSettingsService';
import EventCardCorrelationRepository from '@/repositories/EventCardCorrelationRepository';


export default {
  name: 'EventMappingsFilter',
  mixins: [dateHelpers],
  props: {
    filterModel: {
      type: Object,
      default: () => {
      }
    },
  },
  components: {},
  data() {
    return {
      filter: [''],
      statusList: [],
    };
  },
  watch: {
    $route(to) {
      this.checkRoute(to);
    }
  },
  async created() {
    if (UserSettingsService.has('EventMappingsFilter_open', this.user.id)) {
      this.filter = ['p1'];
    }
    this.statusList = await EventCardCorrelationRepository.getCorrelationStatusesAsync();
    await this.checkRoute(this.$route);
  },
  computed: {
    ...mapGetters('dictionaries', ['baseVoltages',
      'estimations',
      'shortCircuitForms',
      'shortCircuitPlaces',
      'energyObjectPsrTypes',
      'secondEquipmentPsrTypes',
      'registrationGroups',
      'faultedPhases',
      'bmmnCheckTypes'
    ]),
    ...mapGetters('identity', ['userHasRole', 'userHasRoleGroup', 'user']),
  },
  methods: {
    async checkRoute(route) {
      if (route.query && route.query.fromNot) {
        this.filterModel.statuses = this.userHasRoleGroup('ExternalUser') ? [3] : [2];
        this.$nextTick(() => {
          this.$router.replace({ name: 'EventMappings' });
        });
        // this.$emit('loadData');
      }
    },
    handleChange(val) {
      if (val.find((el) => el === 'p1')) {
        UserSettingsService.set('EventMappingsFilter_open', true, this.user.id);
      } else {
        UserSettingsService.delete('EventMappingsFilter_open', this.user.id);
      }
      const objectLength = Object.keys(val);
      if (objectLength && objectLength.length > 0) {
        // this.filterModel.isActive = true;
        this.$emit('active-change', true);
      } else {
        // this.filterModel.isActive = false;
        this.$emit('active-change', false);
      }
    },
    clearFilters() {
      this.$emit('clear-filters');
    },
  }
};
</script>

<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" name="p1" class="events_filter-collapse-item">
      <template v-slot:title>
        <span>
          <span>Фильтр</span>
          <el-button class="ml-5"
              plain
              size="mini"
              type="default" 
              @click.stop="clearFilters" 
              style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </span>
      </template>
      <el-row class="m-0" :gutter="5">
        <el-col :span="4">
          <span class="mr-2" style="min-width:13rem;">Статус сопоставления события:</span>
          <el-select multiple filterable :collapse-tags="filterModel.statuses.length > 5"
                     clearable v-model="filterModel.statuses" size="mini"
                     popper-class="search-select">
            <el-option
              v-for="item in statusList"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <span class="mr-2" style="min-width:13rem;">Номер события:</span>
          <el-input size="mini" v-model="filterModel.eventNumber" clearable
                    id="incidients-filter--number"></el-input>
        </el-col>
        <el-col :span="12">
          <el-row class="m-0">
            <el-col :span="24">
              Дата и время события события c-по:
            </el-col>

            <el-col :span="12">
              <el-date-picker v-model="filterModel.utcFromDt"
                              v-mask="'##.##.#### ##:##'"
                              size="mini"
                              style="width: 100%"
                              type="datetime"
                              range-separator="-"
                              placeholder="дд.мм.гггг чч:мм"
                              format="dd.MM.yyyy HH:mm"
                              :picker-options="datePickerOptions">
              </el-date-picker>
            </el-col>
            <el-col :span="1" style="text-align: center">-</el-col>
            <el-col :span="11">
              <el-date-picker v-model="filterModel.utcToDt"
                              v-mask="'##.##.#### ##:##'"
                              size="mini"
                              style="width: 100%"
                              type="datetime"
                              range-separator="-"
                              placeholder="дд.мм.гггг чч:мм"
                              format="dd.MM.yyyy HH:mm"
                              :default-time="'23:59:00'"
                              :picker-options="datePickerOptions">
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- <el-row class="m-0 filter">
        <el-col :span="8" class="ml-2 filter--container">
          <el-button @click="clearFilters" type="text"
                     style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </el-col>
      </el-row> -->
    </el-collapse-item>
  </el-collapse>
</template>


<style scoped lang="scss">

.events_filter-collapse-item :deep(.el-collapse-item__content) {
  padding-bottom: 0 !important;
}

</style>
