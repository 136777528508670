<script>

import { mapGetters } from 'vuex';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import eventsApi from '@/api/eventsAndIncidents/events/';
import udEventsApi from '@/api/eventsAndIncidents/userDataEvents/';
import filterStorage from '@/utilities/filterStorage';
import EventCardCorrelationRepository from '@/repositories/EventCardCorrelationRepository';
import EventMappingsFilter from '@/views/EventsAndIncidents/EventMapping/EventMappingsFilter.vue';
import moment from 'moment';
import EventMappingModalStore from '@/views/EventsAndIncidents/EventMapping/EventMappingModalStore';
import EventMappingModal from '@/views/EventsAndIncidents/EventMapping/EventMappingModal.vue';
import UploadModal from '@/views/EventsAndIncidents/UserUpload/UploadModal.vue';

export default {
  name: 'EventMappings',
  mixins: [formatters, dateHelpers],
  components: {
    EventMappingModal,
    EventMappingsFilter,
    pagination,
    UploadModal
  },

  watch: {
    filterModel: {
      handler(newVal) {
        this.getData();
        filterStorage.saveFilter('EventMappings-filter', newVal);
      },
      deep: true
    },
    sortModel: {
      handler(newVal) {
        this.getData();
        filterStorage.saveFilter('EventMappings-sort', newVal);
      },
      deep: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      events: [],
      totalCount: 0,
      fileText: 'Выберите XML-файл формата ПК "Анализ 2009":',
      sortModel: filterStorage.getFilterOrDefault('EventMappings-sort', {
        prop: 'extEventDt',
        order: 'descending',
        pageNumber: 1,
        pageSize: 20,
      }),
      filterModel: {
        statuses: [],
        eventNumber: '',
        utcFromDt: null,
        utcToDt: null,


      },
      filterActive: true,
      eventCard: {},
    };
  },
  computed: {
    ...mapGetters('identity', ['user', 'userHasRole', 'userHasRoleGroup']),
    ...mapGetters('dictionaries', ['estimations']),
  },
  async created() {
    this.myModalStore = new EventMappingModalStore(this.userHasRoleGroup('ExternalUser'), { toUserDate: this.toUserDate, toServerUtcDate: this.toServerUtcDate });
    const filter = filterStorage.getFilter('EventMappings-filter');
    const sort = filterStorage.getFilter('EventMappings-sort');
    if (filter) {
      // this.filterModel = { ...this.filterModel, ...filter };
      Object.keys(this.filterModel)
        .forEach((key) => {
          this.$set(this.filterModel, key, filter[key]);
        });
      if (sort) {
        // this.sortModel = { ...this.sortModel, ...sort };
        Object.keys(this.sortModel)
          .forEach((key) => {
            this.$set(this.sortModel, key, sort[key]);
          });
      }
    } else {
      await this.getData(this.pageNumber, this.pageSize);
    }

    // this.tableHeight = this.getTableHeight();
  },
  methods: {
    handleUploadDialogOpen() {
      this.dialogVisible = true;
    },
    eventEquipment(row) {
      if (row.primaryEquipmentName && !row.secondEquipmentPanelName) {
        return row.primaryEquipmentName;
      }
      if (!row.primaryEquipmentName && row.secondEquipmentPanelName) {
        return row.secondEquipmentPanelName;
      }
      if (row.primaryEquipmentName && row.secondEquipmentPanelName) {
        return `${row.secondEquipmentPanelName}; ${row.secondEquipmentPanelName}`;
      }
      return '';
    },
    async getData() {
      this.loading = true;

      const params = {
        pageNumber: this.sortModel.pageNumber,
        pageSize: this.sortModel.pageSize,
        statuses: this.filterModel.statuses,
        eventNumber: this.filterModel.eventNumber,
        utcFromDt: this.filterModel.utcFromDt ? moment(this.filterModel.utcFromDt)
          .local()
          .format('YYYY-MM-DD HH:mm:ss') : null,
        utcToDt: this.filterModel.utcToDt ? moment(this.filterModel.utcToDt)
          .local()
          .format('YYYY-MM-DD HH:mm:ss') : null,
        sortField: this.sortModel.prop,
        sortDesc: this.sortModel.order === 'descending'
      };
      try {
        const res = await EventCardCorrelationRepository.getPagingAsync(params);
        this.events = res.items;
        this.totalCount = res.totalCount;
      } catch (e) {
        this.$message.error(e.message);
      } finally {
        this.loading = false;
      }
    },
    async handleSizeChange(event) {
      this.sortModel.pageNumber = 1;
      this.sortModel.pageSize = event;
      await this.getData();
    },
    async handleCurrentChange(event) {
      this.sortModel.pageNumber = event;
      await this.getData();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortModel.order = null;
        this.sortModel.prop = null;
      } else {
        this.sortModel.order = event.order;
        this.sortModel.prop = event.prop;
      }
    },
    clearFilters() {
      this.filterModel.statuses = [];
      this.filterModel.eventNumber = '';
      this.filterModel.utcFromDt = null;
      this.filterModel.utcToDt = null;
    },

    handleDelete(event) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Подтверждение', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(async () => {
          const deleteAction = this.userHasRoleGroup('ExternalUser')
            ? udEventsApi.delete
            : eventsApi.delete;

          const deleteResult = await deleteAction(event.id);

          if (deleteResult.data.succeeded === false) {
            this.$alert(deleteResult.data.resultMessage, 'Ошибка');
          } else {
            await this.getData();
          }
        })
        .catch(() => {
        });
    },
    async handleOpenCor(corId) {
      await this.myModalStore.openByIdAsync(corId);
    }
  },
};
</script>


<template>
  <div class="my-flex-container-column">
    <event-mappings-filter
      @active-change="filterActive = $event" :filter-model="filterModel"
      @clear-filters="clearFilters"/>
    <el-row class="m-0" :gutter="20">
      <template v-if="userHasRole('PkAnalysisXmlImport')">
        <el-col :span="4">
          <el-button @click="handleUploadDialogOpen" type="text" icon="el-icon-circle-plus-outline">
            Загрузить событие из XML
          </el-button>
        </el-col>
        <el-col :offset="4" :span="6">
          <pagination
            style="text-align: center"
            :total-count="totalCount"
            :page-size.sync="sortModel.pageSize"
            :current-page.sync="sortModel.pageNumber"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </template>
      <template v-else>
        <el-col :offset="8" :span="6">
          <pagination
            style="text-align: center"
            :total-count="totalCount"
            :page-size.sync="sortModel.pageSize"
            :current-page.sync="sortModel.pageNumber"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </template>
    </el-row>
    <div class="my-flex-data-content">
      <el-table v-loading="loading"
                style="width: 100%"
                height="100%"
                :data="events"
                :default-sort="this.sortModel"
                @sort-change="handleSortChange">

        <el-table-column width="64">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-search"
                       @click="handleOpenCor(scope.row.id )"></el-button>
          </template>
        </el-table-column>

        <el-table-column prop="statusName" label="Статус сопоставления" sortable="custom"
        />
        <el-table-column prop="intEventNumber" label="Номер события (внутр.)" sortable="custom"
                         :formatter="eventNumberRenderer"/>
        <el-table-column prop="intEventDt" label="Дата и время (внутр.)" sortable="custom"
                         :formatter="dateTimeRenderer"
        />
        <el-table-column prop="extEventNumber" label="Номер события (внеш.)" sortable="custom"
                         :formatter="eventNumberRenderer"/>
        <el-table-column prop="extEventDt" label="Дата и время (внеш.)" sortable="custom"
                         :formatter="dateTimeRenderer"
        />
        <el-table-column width="42px">
          <template slot-scope="scope">
            <el-popconfirm
              title="Удалить запись?"
              @confirm="handleDelete(scope.row)"
            >
              <template v-slot:reference>
                <el-button type="text" icon="el-icon-delete"></el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <upload-modal v-if="dialogVisible"
                  v-model="dialogVisible"
                  :file-text="fileText"
                  @start-upload="getData"/>
    <event-mapping-modal :my-store="this.myModalStore"></event-mapping-modal>
  </div>
</template>


<style>


.el-table:before {
  height: 0px;
}
</style>
